import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import '../styles/style.css';
import { useNavigate } from "react-router-dom";
import DataFiltering from '../components/DataFiltering';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
export const approvedEmails = [
  'pranaypise123456@gmail.com',
  'admin1@gmail.com',
]

function Admin() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
      useEffect(() => {
        setLoading(true);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if(!approvedEmails.includes(user?.email.toLocaleLowerCase())){
            navigate('/');
            return;
          }
          setLoading(false);
        });
    
        return () => unsubscribe();
      }, []);

      if(loading){
        return <div>Validating...</div>
      }
  return (
    <>
      <section className="admin-page">
       <div>Manage All of your Admin tasks here</div>
       <DataFiltering/>
      </section>
     
      <Footer />
    </>
  );
}

export default Admin;
